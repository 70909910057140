import { NO_MIN, NO_MAX } from '~/assets/strings';
import { Presenter } from '~/framework/Presenter';
import { currency } from '~/global-contexts/utils/number';
import { useLocalization } from '~/hooks/useLocalization';
import { PresentUnitParams } from '~/types/Unit';
import { User } from '~/use-cases/mainAppState';

const { t } = useLocalization();

export class CompanyUnitSearchParamsPresenter extends Presenter<PresentUnitParams> {
  protected createModel(state: User): PresentUnitParams {
    const searchParams = {
      AvailableFrom: state.unitParams?.AvailableFrom,
      MaxRentalPrice: state.unitParams?.MaxRentalPrice,
      MinBaths: state.unitParams?.MinBaths,
      MinBeds: state.unitParams?.MinBeds,
      MinRentalPrice: state.unitParams?.MinRentalPrice,
      Page: state.unitParams?.Page,
      PageSize: state.unitParams?.PageSize,
      Pinned: state.unitParams?.Pinned,
      PropertyType: state.unitParams?.PropertyType,
      Search: state.unitParams?.Search,
    };
    return {
      searchParams,
      priceRangeOptions: this.getPriceRangeOptions(),
      minPriceOptions: this.getMinPriceOptions(),
      maxPriceOptions: this.getMaxPriceOptions(),
      priceDisplay: this.getPriceDisplay(
        state.unitParams?.MinRentalPrice?.toString() ?? '',
        state.unitParams?.MaxRentalPrice?.toString() ?? ''
      ),
      bdBaDisplay: this.getBdBaDisplay(state.unitParams?.MinBaths?.toString() ?? '', state.unitParams?.MinBeds?.toString() ?? ''),
    };
  }

  private getBdBaDisplay(bd: string, ba: string) {
    if (!bd && !ba) {
      return '';
    }

    if (!bd) {
      return `${ba}+ ba`;
    }

    if (!ba) {
      return `${bd}+ bd`;
    }

    return `${bd}+ bd, ${ba}+ ba`;
  }

  private getPriceDisplay(min: string, max: string) {
    if (!min && !max) {
      return '';
    }

    if (!min) {
      return `UP to ${currency(max)}`;
    }

    if (!max) {
      return `${currency(min)}+`;
    }

    return `${currency(min)}-${currency(max)}`;
  }

  private getMinPriceOptions() {
    return [
      { label: t(NO_MIN), value: '' },
      { label: `${currency(200)}`, value: '200' },
      { label: `${currency(400)}`, value: '400' },
      { label: `${currency(600)}`, value: '600' },
      { label: `${currency(800)}`, value: '800' },
    ];
  }

  private getMaxPriceOptions() {
    return [
      { label: t(NO_MAX), value: '' },
      { label: `${currency(1000)}`, value: '1000' },

      { label: `${currency(2000)}`, value: '2000' },
      { label: `${currency(3000)}`, value: '3000' },
      { label: `${currency(4000)}`, value: '4000' },
      { label: `${currency(5000)}`, value: '5000' },
      { label: `${currency(6000)}`, value: '6000' },
    ];
  }

  private getPriceRangeOptions() {
    return [
      { label: `<${currency(1000)}`, value: '0-1000' },
      { label: `${currency(1001)}-${currency(2000)}`, value: '1001-2000' },
      { label: `${currency(2001)}-${currency(3000)}`, value: '2001-3000' },
      { label: `${currency(3001)}-${currency(4000)}`, value: '3001-4000' },
      { label: `${currency(4001)}-${currency(5000)}`, value: '4001-5000' },
      { label: `>${currency(5001)}`, value: '5001-0' },
    ];
  }
}
