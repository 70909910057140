import { onMount, createSignal, For, Show, createMemo } from 'solid-js';
import IconEmpty from '~/assets/icons/empty.svg';
import IconLoaderAnimation from '~/assets/icons/loderAnimation.svg';
import {
  PRICE,
  MIN,
  MAX,
  NO_MIN,
  NO_MAX,
  HOME_TYPE,
  SINGLE_FAMILY,
  MULTI_FAMILY,
  COMMERCIAL,
  CONDO,
  TOWN_HOME,
  OTHER,
  RESET,
  SELECT_HOME_TYPE,
  SLIDE_UP_TO_LOAD_MORE,
  MOVE_IN_DATE,
  EMPTY_DESC,
  SEARCH_FOR_YOUR_DREAM_HOME,
} from '~/assets/strings';
import { Button } from '~/components/common/buttons';
import { PropertyItem } from '~/components/property-item/PropertyItem';
import { IconSearch } from '~/components/ui';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { CompanyUnitListingPresenter } from '~/presenters/CompanyUnitListingPresenter';
import { CompanyUnitSearchParamsPresenter } from '~/presenters/CompanyUnitSearchParamsPresenter';
import { GetCompanyUnitsListingUseCase } from '~/use-cases/company-unit-listing/getCompanyListingUseCase';
import { UpdateListingSearchParamsUseCase } from '~/use-cases/company-unit-listing/updateListingSearchParamsUseCase';
import { debounce } from '~/utils/tools';
import { SearchBedBaths } from './SearchBedBaths';
import { SearchMoveInDate } from './SearchMoveindate';
import { SearchRange } from './SearchRange';
import { SearchSelect } from './SearchSelect';

const { t } = useLocalization();

export const homeTypeOptions = [
  { label: t(SINGLE_FAMILY), value: SINGLE_FAMILY },
  { label: t(MULTI_FAMILY), value: MULTI_FAMILY },
  { label: t(COMMERCIAL), value: COMMERCIAL },
  { label: t(CONDO), value: CONDO },
  { label: t(TOWN_HOME), value: TOWN_HOME },
  { label: t(OTHER), value: OTHER },
];

export const PropertiesSearchList = () => {
  const [page, setPage] = createSignal(1);
  const [pageSize] = createSignal(9);
  const { execute: updateParams, isLoading: isUpdateParamsLoading } = useUseCase(UpdateListingSearchParamsUseCase);
  const { model: searchParamsConfig } = usePresenter(CompanyUnitSearchParamsPresenter);
  const { model: unitListing } = usePresenter(CompanyUnitListingPresenter);
  const [loaderRef, setLoaderRef] = createSignal<HTMLDivElement | null>(null);

  const { execute: getUnits } = useUseCase(GetCompanyUnitsListingUseCase);

  const priceConfig = createMemo(() => {
    return {
      start: {
        title: t(MIN),
        placeholder: t(NO_MIN),
        options: searchParamsConfig()?.minPriceOptions ?? [],
      },
      end: {
        title: t(MAX),
        placeholder: t(NO_MAX),
        options: searchParamsConfig()?.maxPriceOptions ?? [],
      },
    };
  });

  const handleChangeParams = (key: string, value: any) => {
    const params: any = {
      ...searchParamsConfig()?.searchParams,
    };

    switch (key) {
      case 'bads&baths':
        params.MinBaths = value.MinBaths;
        params.MinBeds = value.MinBeds;

        break;
      case 'price':
        params.MinRentalPrice = value.start;
        params.MaxRentalPrice = value.end;

        break;
      default:
        params[key] = value;

        break;
    }
    updateParams(params);
    setPage(1);
    getUnits({
      ...params,
      Page: 1,
      PageSize: pageSize(),
    });
  };

  onMount(() => {
    getUnits({
      ...searchParamsConfig()?.searchParams,
      Page: 1,
      PageSize: pageSize(),
    });
  });

  const reset = () => {
    updateParams({});
    getUnits({
      Page: 1,
      PageSize: pageSize(),
    });
  };

  const loadMore = () => {
    if ((unitListing()?.totalPages ?? 1) <= page()) return;
    setPage(page() + 1);
    getUnits({
      ...searchParamsConfig()?.searchParams,
      Page: page(),
      PageSize: pageSize(),
    });
  };

  onMount(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isUpdateParamsLoading()) {
          loadMore();
        }
      },
      { threshold: 0.1 }
    );

    const currentLoaderRef = loaderRef();
    if (currentLoaderRef) {
      observer.observe(currentLoaderRef);
    }

    return () => {
      if (currentLoaderRef) {
        observer.unobserve(currentLoaderRef);
      }
    };
  });

  // eslint-disable-next-line solid/reactivity
  const onSearch = debounce((value: string) => {
    handleChangeParams('Search', value || undefined);
  }, 500);

  return (
    <>
      <div class="fixed top-[135px]  z-40 h-[116px] w-full bg-border-level01">
        <div class="mx-auto h-full w-max-container-width">
          <div class="flex h-full flex-row items-center gap-4 ">
            <div class="flex flex-1 flex-row gap-4">
              <div class="flex items-center gap-3 rounded-lg border border-[#CBD4EB] px-4 py-[14px] text-[#222735]">
                <IconSearch class="pointer-events-none size-5 cursor-pointer text-[#CBD4EB] transition-transform group-aria-expanded:rotate-180" />
                <input
                  type="text"
                  class="w-full bg-transparent placeholder:text-auxiliary-text focus:outline-none"
                  onInput={(e) => {
                    onSearch(e.target.value);
                  }}
                  value={searchParamsConfig()?.searchParams.Search || ''}
                  placeholder={t(SEARCH_FOR_YOUR_DREAM_HOME)}
                />
              </div>
              <SearchRange
                valueDisplay={searchParamsConfig()?.priceDisplay}
                placeholder={t(PRICE)}
                onChange={(value) => handleChangeParams('price', value)}
                value={{
                  start: searchParamsConfig()?.searchParams.MinRentalPrice || '',
                  end: searchParamsConfig()?.searchParams.MaxRentalPrice || '',
                }}
                title={t(PRICE)}
                start={priceConfig().start}
                end={priceConfig().end}
              />
              <SearchSelect
                valueDisplay={searchParamsConfig()?.searchParams.PropertyType ? t(searchParamsConfig()?.searchParams.PropertyType) : ''}
                placeholder={t(SELECT_HOME_TYPE)}
                onChange={(value) => handleChangeParams('PropertyType', value)}
                value={searchParamsConfig()?.searchParams.PropertyType as string}
                title={t(HOME_TYPE)}
                options={homeTypeOptions}
              />
              <SearchMoveInDate
                valueDisplay={
                  searchParamsConfig()?.searchParams.AvailableFrom
                    ? `${t(MOVE_IN_DATE)} ${searchParamsConfig()?.searchParams.AvailableFrom}`
                    : ''
                }
                moveInDate={searchParamsConfig()?.searchParams.AvailableFrom as string}
                onChange={(value) => handleChangeParams('AvailableFrom', value)}
              />
              <SearchBedBaths
                valueDisplay={searchParamsConfig()?.bdBaDisplay}
                baths={searchParamsConfig()?.searchParams.MinBaths || 0}
                beds={searchParamsConfig()?.searchParams.MinBeds || 0}
                onChange={(value) => handleChangeParams('bads&baths', value)}
              />
            </div>

            <div class="flex shrink-0 justify-end gap-2">
              <Show when={JSON.stringify(searchParamsConfig()?.searchParams) !== '{}'}>
                <Button class="bg-white text-primary-color" onClick={reset}>
                  {t(RESET)}
                </Button>
              </Show>
            </div>
          </div>
        </div>
      </div>
      <div class="mx-auto mt-[150px] w-max-container-width">
        <div class="mt-[46px] w-full">
          <div class="mb-[400px] mt-[57px]">
            <Show
              when={unitListing()?.allItems.length}
              fallback={
                <div class="flex w-full flex-col items-center justify-center">
                  <img src={IconEmpty} class="mt-[137px] h-[350px] w-modal-sm" />
                  <div class="text-body-sm mt-[24px] text-center text-text-level03">{t(EMPTY_DESC)}</div>
                </div>
              }>
              <div class=" grid w-full grid-cols-3 gap-6 ">
                <For each={unitListing()?.allItems}>{(item) => <PropertyItem data={item} />}</For>
              </div>
            </Show>

            <div class="mt-[56px] flex w-full flex-col items-center justify-center" ref={setLoaderRef}>
              <Show when={(unitListing()?.totalPages ?? 1) > page() && unitListing()?.items.length}>
                <img src={IconLoaderAnimation} class="w-[200px] " />
                <div>{t(SLIDE_UP_TO_LOAD_MORE)}</div>
              </Show>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
